import React from "react";
import {transformMarkdownToHtml} from "../utils/transformMarkdownToHtml";
import heroSection from "../../content/settings/hero_section";

export default function AboveTheFold() {
    const column_1 = transformMarkdownToHtml(heroSection.column_1);
    const column_2 = transformMarkdownToHtml(heroSection.column_2);
    const description = transformMarkdownToHtml(heroSection.description);

    return (
        <section className="aboveTheFold">
            <div className="lineBox">
                <span className="line-1">{null}</span>
                <span className="line-2">{null}</span>
                <span className="line-3">{null}</span>
                <span className="line-4">{null}</span>
                <span className="line-5">{null}</span>
            </div>

            <div className="hero flex container">
                <h1>{heroSection.main_title}</h1>
                <div className="descriptions">
                    <h2>
                        {heroSection.sub_title}
                    </h2>

                    <div className="flex">

                        <div className="column">
                            <div dangerouslySetInnerHTML={{
                                __html: column_1
                            }}>
                            </div>
                        </div>

                        <div className="column">
                            <div dangerouslySetInnerHTML={{
                                __html: column_2
                            }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="scrollDown container">
                <div className="lineBox">
                    <span className="line-5">{null}</span>
                </div>

                <div className="textBox flex" dangerouslySetInnerHTML={{
                    __html: description
                }}>
                </div>

                <div id={"scrollDownOneFold"} className="hexagon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         viewBox="0 0 24 24"
                         fill="none" stroke="#262626"
                         strokeWidth="2"
                         strokeLinecap="round"
                         strokeLinejoin="round"
                         className="feather arrow feather-arrow-down"
                    >
                        <line x1="12" y1="5" x2="12" y2="19">{null}</line>
                        <polyline points="19 12 12 19 5 12">{null}</polyline>
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="51.89" viewBox="0 0 60 64.861">
                        <defs>
                            <linearGradient id="a" y1=".5" x2="1" y2=".5" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#009efa"/>
                                <stop offset="1" stopColor="#845ec2"/>
                            </linearGradient>
                        </defs>
                        <g data-name="Group 103">
                            <path data-name="Path 306"
                                  d="M25.857 3.212l-15.714 9.073A14.284 14.284 0 0 0 3 24.656V42.8a14.284 14.284 0 0 0 7.143 12.371l15.714 9.073a14.288 14.288 0 0 0 14.286 0l15.65-9.036A14.412 14.412 0 0 0 63 42.728V24.656a14.284 14.284 0 0 0-7.143-12.371L40.143 3.212a14.288 14.288 0 0 0-14.286 0z"
                                  transform="translate(-3 -1.299)" fill="white"/>
                        </g>
                    </svg>

                </div>
            </section>
            <svg className="stars" xmlns="http://www.w3.org/2000/svg"
                 width="757.856" height="800.954" viewBox="0 0 757.856 1030.954">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.402" y1="1" x2="0.379" y2="-0.202"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#1c60ba"/>
                        <stop offset="1" stopColor="#1c3376"/>
                    </linearGradient>
                </defs>
                <path id="Union_1" data-name="Union 1"
                      d="M-1876.654,897.169-1990,814.158h140.359l43.192-134.323,43.192,134.323h139.72l-112.7,82.85,43.193,133.786-113.4-82.69-113.4,82.85Zm355.036-233.614-113.4,82.152,43.191-133.786-112.7-82.85h139.719l43.193-133.786,43.191,133.786h140.359l-113.4,82.85,43.193,133.786ZM-1485.9,216.8l-113.346-83.012h140.359L-1415.7,0l43.192,133.785h140.359l-113.4,82.85,43.191,134.323L-1415.7,268.269l-113.4,82.85Z"
                      transform="translate(1990)" fill="url(#linear-gradient)"/>
            </svg>
        </section>
    )
};