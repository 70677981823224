export const exitPopup = () => {
    const init = () => {

        if (!CookieService.getCookie(`exitIntentShown`)) {
            setTimeout(() => {
                document.addEventListener('mouseout', mouseEvent);
                document.addEventListener('keydown', exit);
                document.addEventListener('click', exit);
            }, 1000);
        }
    }

    const exit = e => {
        const shouldExit =
            [...e.target.classList].includes('exit-intent-popup') ||
            e.target.className === 'close' ||
            e.keyCode === 27; // user hits escape

        if (shouldExit) {
            document.querySelector('.exit-intent-popup').classList.remove('visible');
        }
    };

    const mouseEvent = e => {
        const shouldShowExitIntent =
            !e.toElement &&
            !e.relatedTarget &&
            e.clientY < 10;

        if (shouldShowExitIntent) {
            document.removeEventListener('mouseout', mouseEvent);
            document.querySelector('.exit-intent-popup').classList.add('visible');

            CookieService.setCookie(`exitIntentShown`, true, 30);
        }
    };

    return {
        init: init
    }
}

const CookieService = {
    setCookie(name, value, days) {
        let expires = '';

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '')  + expires + ';';
    },

    getCookie(name) {
        const cookies = document.cookie.split(';');

        for (const cookie of cookies) {
            if (cookie.indexOf(name + '=') > -1) {
                return cookie.split('=')[1];
            }
        }

        return null;
    }
}