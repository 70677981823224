import React, {useEffect} from "react";
import {exitPopup} from "./modals/exitPopup";
import exitPopupFields from "../../content/settings/exit_popup";

const Modals = () => {
    const imageUrl = exitPopupFields.exit_popup_image;
    const popupLink = exitPopupFields.exit_popup_link;

    useEffect(() => {
        if (isExitPopupEnabled()) {
            exitPopup().init();
        }
    });

    const isExitPopupEnabled = () => {
        return exitPopupFields.exit_popup_enabled;
    }

    const exitPopupTemplate = (
        <div className="exit-intent-popup">
            <div className="popup-body">
                <a target="_blank" href={popupLink}>
                    <img src={imageUrl} alt="exit popup image" />
                </a>
                <span className="close" />
            </div>
        </div>
    )

    if (!isExitPopupEnabled()) {
        return null
    }

    return (
        exitPopupTemplate
    )
}

export default Modals;