import React, {useEffect} from "react";
import $ from "jquery";
import {Link} from "gatsby";
import "../assets/sass/style.scss";
import AboveTheFold from "./aboveTheFold";
import Modals from "./modals";
import logo from "../assets/images/crosssec_logo_horizontal_white_rgb.svg";
import favicon from "../assets/images/favicon/favicon-32x32.png"
import Helmet from 'react-helmet';
import {transformMarkdownToHtml} from "../utils/transformMarkdownToHtml";
import settings from "../../content/settings/global_settings";

const Layout = ({location, title, children}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath
    let header

    if (isRootPath) {
        header = (
            <h1 className="main-heading">
                <Link to="/">{title}</Link>
            </h1>
        )
    } else {
        header = (
            <Link className="header-link-home" to="/">
                {title}
            </Link>
        )
    }

    useEffect(() => {
        $(document).ready(() => {
            window.onscroll = function() {scrollFunction()};

            function scrollFunction() {

                if ($(window).scrollTop() > $(".aboveTheFold").height()) {
                    $("#scrollDownToFooter").addClass("scroll");
                } else {
                    $("#scrollDownToFooter").removeClass("scroll");
                }
            }

            $("#scrollDownOneFold").on('click', function() {
                $('html, body').animate({
                    scrollTop: $(".aboveTheFold").height()
                });
            });

            $("#scrollDownToFooter").on('click', function() {
                $('html, body').animate({
                    scrollTop: $(".topFooter1").offset().top
                }, 1500);
            });
        })
    });

    return (
        <div className="global-wrapper" data-is-root-path={isRootPath}>
            <Helmet>
                <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
                <meta name="robots" content="index, follow" />
                <meta name="format-detection" content="telephone=no" />
                <meta property="og:image" content="https://f.hubspotusercontent30.net/hubfs/2892836/gdprbirsagokhu-og.png"/>
                <meta name="keywords" content="crosssec, crosssec solutions, crossec, gdpr, adatvedelem, adatvédelem, üzleti, tanácsadás, naih, bírság, büntetés, gdpr bírság, gdpr büntetés, gdpr birsag, gdpr buntetes, penzbuntetes pénzbüntetés, adatvédelmi bírság, adatvedelmi birsag, adatvédelmi büntetés, adatvedelmi buntetes, gdpr tanácsadás, gdpr tanacsadas, dpo,  adatvédelmi rendelet, adatvedelmi rendelet, gdpr rendelet, gdpr figyelő, gdpr figyelo" />
            </Helmet>
            <main>
                <AboveTheFold />
                {children}
            </main>
            <footer className="footer">
                <div className="topFooter1 container">
                    <svg className="stars1" xmlns="http://www.w3.org/2000/svg"
                         width="352.856" height="480.954"
                         viewBox="0 0 757.856 1030.954">
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.402" y1="1" x2="0.379" y2="-0.202"
                                            gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#1c60ba"/>
                                <stop offset="1" stopColor="#1c3376"/>
                            </linearGradient>
                        </defs>
                        <path id="Union_1" data-name="Union 1"
                              d="M-1876.654,897.169-1990,814.158h140.359l43.192-134.323,43.192,134.323h139.72l-112.7,82.85,43.193,133.786-113.4-82.69-113.4,82.85Zm355.036-233.614-113.4,82.152,43.191-133.786-112.7-82.85h139.719l43.193-133.786,43.191,133.786h140.359l-113.4,82.85,43.193,133.786ZM-1485.9,216.8l-113.346-83.012h140.359L-1415.7,0l43.192,133.785h140.359l-113.4,82.85,43.191,134.323L-1415.7,268.269l-113.4,82.85Z"
                              transform="translate(1990)" fill="url(#linear-gradient)"/>
                    </svg>

                    <h6>{settings.contact_title}</h6>
                    <p className="text">
                        {settings.contact_subtitle}
                    </p>

                    <div dangerouslySetInnerHTML={{
                        __html: settings.contact_hubspot_cta
                    }} />


                </div>

                <div className="bottomFooter container">
                    <ul>
                        <li className="menu">
                            <h4>Termékeink</h4>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/">Üzleti tanácsadás</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://norma.crosssec.com/">Crosssec Norma</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/letoltheto-segedanyagok/">Letölthető segédanyagok</a>
                            </div>
                        </li>
                        <li className="menu">
                            <h4>Cégünk</h4>
                            <div>
                                <a target="_blank"  className="menuItem" href="https://crosssec.com/rolunk/">Rólunk</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/csapatunk/">Csapatunk</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/termekeink/">Termékeink</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/karrier/">Karrier</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem" href="https://blog.crosssec.com/">Blog</a>
                            </div>
                        </li>
                        <li className="menu">
                            <h4>Hasznos linkek</h4>
                            <div>
                                <a target="_blank" className="menuItem" href="https://crosssec.com/kapcsolat/">Kapcsolat</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem"
                                   href="https://crosssec.link/adatvedelmi-dokumentumok">Adatvédelmi Dokumentumok</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem"
                                   href="https://crosssec.link/minosegpolitika">Minőség- és információbiztonsági politikai nyilatkozat</a>
                            </div>
                            <div>
                                <a target="_blank" className="menuItem"
                                   href="https://crosssec.link/ugyfelszolgalati-es-panaszkezelesi-szabalyzat">Ügyfélszolgálati és panaszkezelési szabályzat</a>
                            </div>
                            <div>
                                <a className="menuItem"
                                   href="https://gdprbirsagok.hu#clear-cookie-preferences">Süti beállítások törlése</a>
                            </div>
                        </li>


                        <li className="social">
                            <a className="mainLogo" href="https://crosssec.com" rel="home">
                                <img alt="crosssec" src={logo} />
                            </a>

                            <div className="socialIcon">

                                <a href="https://www.facebook.com/crosssec" rel="noreferrer" target="_blank" className="fa fa-facebook">
                                </a>

                                <a href="https://twitter.com/crosssec_info" rel="noreferrer" target="_blank" className="fa fa-twitter">
                                </a>

                                <a href="https://www.linkedin.com/company/crosssec-solutions/" rel="noreferrer" target="_blank"
                                   className="fa fa-linkedin">
                                </a>

                                <a href="https://www.instagram.com/crosssecsolutions/" rel="noreferrer" target="_blank"
                                   className="fa fa-instagram">
                                </a>
                            </div>
                        </li>
                    </ul>
                    <p className="copyRight"> Copyright © Crosssec Solutions {new Date().getFullYear()}</p>
                </div>

                <Modals />
            </footer>
        </div>
    )
}

export default Layout
